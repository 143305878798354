.imagePhone{
  width: 23.75rem;
  height: 48.230rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: 1024px) {
    margin: 0;
  }
  @media (min-width: 1680px) {
    height: 106.615rem;
    width: 52.5rem;

  }
}
