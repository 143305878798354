html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.verloop-button{
  display: none
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-elevated{
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #FFFFFF;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  border-radius: 10px;
  border: solid 1px #436ab2;
  width: 400px;
  padding: .5em 1em .5em 2.5em;
  } 
  
  .input-elevated::placeholder{
    color: #838D99;
  }
  
  .input-elevated:focus {
    outline: none;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.16);
  }
