*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; /*forcing this property to inherit for each element (By def this prop not inherited)*/
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Light.43b7b4e0.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-LightOblique.d16405f7.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Regular.71191a6f.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-BookOblique.58e14f43.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Medium.922ec9af.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-MediumOblique.3eef5c0f.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/AvenirSemiBold.b680dbf2.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Heavy.45694521.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-HeavyOblique.e981f7b2.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-Black.12f59411.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/Avenir-BlackOblique.3bba35f5.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* font-size: 62.5% i.e 10px by default = 1 rem */
html {
  font-size: 62.5%;
}


/* @media (min-width: 1200px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 59%;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 50%;
  }
} */
/* @media (max-width: 320px) {
  html {
    font-size: 59%;
  }
} */

body {
	height: 100%;
	width: 100%;
	overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Avenir', 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering:optimizeLegibility;

}

input {
  font-family: 'Open Sans', sans-serif;
  font-size: inherit;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 19px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* font-family: 'Proxima Nova','Roboto','IBM Plex Sans', 'Open Sans', sans-serif; */
  font-weight: normal;
}
.verloop-button{
  display: none
}

.neo_custom_accordion > .MuiAccordion-root:before {
  display: none;
}
.imagePhone{
  width: 29rem;
  height: 45.936rem;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: ;

  @media (min-width: 1024px) {
    margin: 0;
  }

  @media (min-width: 1680px) {
    width: 58rem;
    height: 91.872rem;
    margin: 0;
    background-image: url(${vitalInsuranceMobile2});
  }
}

.imagePhone{
  width: 23.75rem;
  height: 48.230rem;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: 1024px) {
    margin: 0;
  }
  @media (min-width: 1680px) {
    height: 106.615rem;
    width: 52.5rem;

  }
}

html {
  touch-action: manipulation;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.verloop-button{
  display: none
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-elevated{
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #FFFFFF;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  border-radius: 10px;
  border: solid 1px #436ab2;
  width: 400px;
  padding: .5em 1em .5em 2.5em;
  } 
  
  .input-elevated::placeholder{
    color: #838D99;
  }
  
  .input-elevated:focus {
    outline: none;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.16);
  }

