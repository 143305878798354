*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; /*forcing this property to inherit for each element (By def this prop not inherited)*/
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-BookOblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirSemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Heavy.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* font-size: 62.5% i.e 10px by default = 1 rem */
html {
  font-size: 62.5%;
}


/* @media (min-width: 1200px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 59%;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 50%;
  }
} */
/* @media (max-width: 320px) {
  html {
    font-size: 59%;
  }
} */

body {
	height: 100%;
	width: 100%;
	overflow: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Avenir', 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering:optimizeLegibility;

}

input {
  font-family: 'Open Sans', sans-serif;
  font-size: inherit;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 19px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  /* font-family: 'Proxima Nova','Roboto','IBM Plex Sans', 'Open Sans', sans-serif; */
  font-weight: normal;
}
.verloop-button{
  display: none
}

.neo_custom_accordion > .MuiAccordion-root:before {
  display: none;
}