.imagePhone{
  width: 29rem;
  height: 45.936rem;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: ;

  @media (min-width: 1024px) {
    margin: 0;
  }

  @media (min-width: 1680px) {
    width: 58rem;
    height: 91.872rem;
    margin: 0;
    background-image: url(${vitalInsuranceMobile2});
  }
}
